@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Inter', 'Arial', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    box-sizing: border-box;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

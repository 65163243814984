@use 'sass:map';

@import '../../scss/utils/mixins';

$ltGrey: map-get($map: $colors, $key: 'ltGrey');
$medGrey: map-get($map: $colors, $key: 'medGrey');
$white: map-get($map: $colors, $key: 'white');

.ForgotPassword {
  @include flexbox($ai: center, $jc: center);
  @include padx(10px);
  background-color: #4B9EFF40;
  height: 100vh;
}

.errorOverlay {
  @include absolute($top: 0, $right: 0, $bottom: 0, $left: 0, $z: 1);
  @include border-rad(2rem);
  @include flexbox($ai: center, $fd: column, $jc: center, $gap: 20px);
  @include pad($top: 8rem, $bottom: 12.5rem);
  @include padx(15px);
  background-color: $white;

  svg {
    @include mar($bottom: 25px);
    @include css3-prefix(transform, scale(0.85));

    @include media(768px) {
      @include css3-prefix(transform, scale(1));
    }
  }

  .title {
    @include font($size: 16px, $weight: 700);
    color: $medGrey;
    line-height: 24px;

    @include media(768px) {
      @include font($size: 20px, $weight: 700);
    }
  }
  .subtitle {
    @include font($size: 11px);
    color: $medGrey;
    line-height: 18px;
    text-align: center;

    @include media(768px) {
      @include font;
    }
  }
  button {
    @include mar($top: 20px);
    height: 40px;
    max-width: 300px;
    width: 100%;
  }
}

.passwordResetOverlay {
  @include absolute($top: 0, $right: 0, $bottom: 0, $left: 0, $z: 1);
  @include border-rad(2rem);
  @include flexbox($ai: center, $fd: column, $jc: flex-start, $gap: 20px);
  @include pad($top: 5.7rem, $bottom: 6.5rem);
  @include padx(7.5rem);
  background-color: $ltGrey;
  position: absolute;
  z-index: 100;
  
  p {
    font-weight: 700;
    line-height: 1.4em;
  }
  button {
    width: 100%;
  }
  label {
    color: $medGrey;
    @include font($size: 12px, $weight: 700);
    line-height: 20px;
    width: 100%;
    text-align: left;
    margin-bottom: -15px;

    &:first-of-type {
      @include mar($top: 0rem);
    }
  }
  input {
    @include border($color: $ltGrey);
    @include border-rad(0.4rem);
    @include font($size: 12px, $weight: 400);
    @include mar($bottom: 2.5rem);
    @include padxy(1rem);
    height: 40px;
    width: 100%;

    &:last-of-type{
      @include mar($bottom: 10px);
    }
  }
  svg{
    height:128px;
    margin-top: 28px;
  }
}